import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Paragraph } from '../components/Paragraph';

export default function FourOhFourPage() {
  const [isMount, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
  }, []);

  if (!isMount) {
    return <div>loading</div>;
  }

  return (
    <FourOhFourPageContainer>
      <FourOhFourPageContent>
        <h1>Ops!</h1>
        <Paragraph big>
          Det er ikke deg, det er meg...Noe gikk galt...
        </Paragraph>
      </FourOhFourPageContent>
    </FourOhFourPageContainer>
  );
}

const FourOhFourPageContainer = styled.div`
  padding: 3.2rem 3.4rem;
  background-color: var(--lightGrey);
`;

const FourOhFourPageContent = styled.div`
  max-width: var(--gridWidth);
  margin: 0 auto;
`;
